body {
  min-width: 992px;
  overflow-y: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1b1b1b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.router-box-outer {
  height: 100%;
  overflow-y: auto;
}
.router-box-inner {
  min-height: 100%;
  position: relative;
  background-color: #29292c;
  // padding: 8px 13px 8px;
  // &::after {
  //   content: " ";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: url("./assets/images/bg.svg");
  //   opacity: .6;
  // }
}
.page_box {
  min-height: calc(100vh - 105px);
}

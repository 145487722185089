* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 定义keyframe动画，命名为blink */
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 定义blink类*/
.blink {
  color: #dd4814;
  animation: blink 2s linear infinite;
}

@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('../../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
}

@mixin common-pingfang-style {
  position: absolute;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 20px;
}

@mixin common-biaotihei-style {
  position: absolute;
  font-size: 22px;
  font-family: 'YouSheBiaoTiHei';
}

@mixin common-content-style {
  background: linear-gradient(180deg, rgba(2, 17, 35, 0.4) 0%, rgba(0, 2, 3, 0.3) 100%);
  backdrop-filter: blur(10px);
}

@mixin content-common-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 400px;
  height: 1px;
  background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
}

.screen_wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .container {
    position: relative;
    overflow-y: scroll;
    overflow: hidden;
    width: 100%;
    background: url(../../assets/images/visualizations/keyVisual@2x.png) no-repeat center/100% 100%;

    .header {
      padding-top: 40px;
      position: relative;
      width: 100%;
      height: 80px;
      background: url(../../assets/images/visualizations/bg-header@2x.png) no-repeat center/100% 80px;

      .fullscreen {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 16px;
        top: 36px;
        height: 32px;
        padding: 8px 16px 8px 12px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        background: url(../../assets//images/visualizations/btn-fullscreen@2x.png) no-repeat center/100% 32px;

        .fullScreenIcon {
          margin-right: 4px;
          width: 16px;
          height: 16px;
          background-color: #fff;
          background: url(../../assets/images/visualizations/icon-fullscreen@2x.png) no-repeat center / 100% 16px;
        }
        .quitfullScreenIcon {
          margin-right: 4px;
          width: 16px;
          height: 16px;
          background-color: #fff;
          background: url(../../assets/images/visualizations/quitFullScreenIcon@2x.png) no-repeat center / 100% 16px;
        }
      }

      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
      }

      .header_left {
        float: left;
        margin-left: 25px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;

        .header_left_address {
          margin-right: 8px;
        }
      }

      .header_title {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 413px;
        line-height: 80px;
        font-family: 'YouSheBiaoTiHei';
        font-size: 32px;
        color: #fff;
      }

      .header_right {
        float: right;
        background-color: pink;
        .header_right_weather {
          position: absolute;
          right: 412px;
        }

        .header_right_time {
          position: absolute;
          right: 154px;
        }
      }
    }

    .content {
      display: flex;
      padding: 24px;
      width: 100%;
      height: 1000px;

      .content_left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 400px;
        height: 100%;

        .content_left_build {
          position: relative;
          width: 100%;
          height: 260px;
          background: linear-gradient(180deg, rgba(2, 17, 35, 0.4) 0%, rgba(0, 2, 3, 0.3) 100%);
          backdrop-filter: blur(10px);

          .content_left_bottom {
            @include content-common-bottom;
          }

          .content_left_build_content {
            width: 100%;
            height: calc(100% - 40px);

            div:nth-of-type(1) {
              position: absolute;
              top: 56px;
              left: 16px;
              width: 193px;
              height: 114px;
              background: url(../../assets/images/visualizations/picBuild@2x.png) no-repeat center/100% 114px;
            }

            div:nth-of-type(2) {
              @include common-pingfang-style;
              top: 64px;
              left: 198px;
              height: 20px;
              line-height: 20px;
            }

            div:nth-of-type(3) {
              @include common-biaotihei-style;
              top: 88px;
              left: 198px;
              height: 29px;
              color: #45ffe0;
              line-height: 29px;
            }

            div:nth-of-type(4) {
              @include common-pingfang-style;
              top: 168px;
              left: 16px;
              height: 20px;
              line-height: 20px;
            }

            div:nth-of-type(5) {
              @include common-biaotihei-style;
              top: 192px;
              left: 16px;
              height: 29px;
              color: #45ffe0;
              line-height: 29px;
            }

            div:nth-of-type(6) {
              @include common-pingfang-style;
              top: 168px;
              left: 198px;
              height: 20px;
              line-height: 20px;
            }

            div:nth-of-type(7) {
              @include common-biaotihei-style;
              top: 192px;
              left: 198px;
              width: 167px;
              height: 29px;
              color: #45ffe0;
              line-height: 29px;
            }
          }
        }

        .content_left_photovoltaic {
          @include common-content-style;
          position: relative;
          width: 100%;
          height: 280px;

          // 安全运行天数
          .runningDays {
            position: absolute;
            z-index: 2;
            right: 38px;
            top: 62px;
            height: 29px;
            font-size: 22px;
            font-family: YouSheBiaoTiHei;
            color: #16dd8e;
            line-height: 29px;
          }

          .content_left_photovoltaic_centerline {
            position: absolute;
            top: 176px;
            left: 0;
            width: 400px;
            height: 1px;
            background: linear-gradient(270deg, rgba(40, 150, 255, 0) 0%, #1dffe0 50%, rgba(40, 150, 255, 0) 100%);
          }

          .content_left_photovoltaic_bottom {
            @include content-common-bottom;
          }

          .content_left_photovoltaic_content {
            margin-top: 40px;
            width: 100%;
            height: calc(100% - 40px);

            .content_left_photovoltaic_pic {
              position: absolute;
              top: 56px;
              left: 16px;
              width: 160px;
              height: 96px;
              background: url(../../assets/images/visualizations/pic-photovoltaic.png) no-repeat center/100% 96px;
            }

            .content_left_photovoltaic_security {
              position: absolute;
              top: 56px;
              left: 192px;
              width: 192px;
              height: 40px;
              background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
              border-radius: 2px;
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;

              .content_left_photovoltaic_security_icon {
                position: absolute;
                top: 12px;
                left: 10px;
                width: 16px;
                height: 16px;
                background: url(../../assets/images/visualizations/icon-security@2x.png) no-repeat center/100% 16px;
              }

              div:nth-of-type(2) {
                @include common-pingfang-style;
                top: 12px;
                left: 28px;
                width: 48px;
                height: 17px;
                line-height: 17px;
                font-size: 12px;
              }
              // 安全运行天数
              div:nth-of-type(3) {
              }

              div:nth-of-type(4) {
                @include common-pingfang-style;
                top: 12px;
                left: 172px;
                width: 12px;
                height: 17px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.65);
                line-height: 17px;
              }
            }
            // 实时功率 (kW)
            div:nth-of-type(3) {
              @include common-pingfang-style;
              top: 110px;
              left: 192px;
              height: 17px;
              line-height: 20px;
              font-size: 12px;
              line-height: 17px;
            }
            // 实时功率值
            span:nth-of-type(1) {
              @include common-biaotihei-style;
              top: 131px;
              left: 192px;
              height: 29px;
              font-size: 22px;
              color: #ffbb45;
              line-height: 29px;
            }
            // 今日发电量 (kWh)
            div:nth-of-type(5) {
              @include common-pingfang-style;
              top: 201px;
              left: 16px;
              height: 20px;
              font-size: 14px;
              line-height: 20px;
            }
            // 今日发电量值
            span:nth-of-type(2) {
              @include common-biaotihei-style;
              top: 225px;
              left: 16px;
              height: 29px;
              font-size: 22px;
              color: #16dd8e;
              line-height: 29px;
            }
            // 本月发电量
            div:nth-of-type(7) {
              @include common-pingfang-style;
              top: 201px;
              left: 208px;
              height: 20px;
              line-height: 20px;
            }
            span:nth-of-type(3) {
              @include common-biaotihei-style;
              top: 225px;
              left: 208px;
              height: 29px;
              font-size: 22px;
              color: #16dd8e;
              line-height: 29px;
            }
            // 本月发电量值
            div:nth-of-type(8) {
              @include common-biaotihei-style;
              top: 225px;
              left: 208px;
              height: 29px;
              font-size: 22px;
              color: #16dd8e;
              line-height: 29px;
            }
          }
        }

        .content_left_energy {
          @include common-content-style;
          position: relative;
          width: 100%;
          height: 380px;

          .content_left_energy_bottom {
            @include content-common-bottom;
          }

          .content_left_energy_content {
            width: 100%;
            height: calc(100% - 40px);

            .content_left_energy_content_pic {
              position: absolute;
              z-index: 2;
              top: 56px;
              left: 17px;
              width: 96px;
              height: 96px;
              background: url(../../assets/images/visualizations/energyPic@2x.png) no-repeat center/100% 96px;
            }

            .content_left_energy_content_pic_cover {
              position: absolute;
              z-index: 3;
              animation-name: rotate;
              animation-duration: 5000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              top: 61px;
              left: 20px;
              width: 88px;
              height: 88px;
              border-radius: 50%;
              background: url(../../assets/images/visualizations/energyPicCover@2x.png) no-repeat center/100% 88px;
            }

            .content_left_energy_content_status {
              position: absolute;
              top: 68px;
              left: 112px;
              width: 272px;
              height: 72px;
              background: url(../../assets/images/visualizations/energyWorkStatus.png) no-repeat center/100% 72px;

              div:nth-of-type(1) {
                @include common-pingfang-style;
                top: 12px;
                left: 32px;
                width: 48px;
                height: 17px;
                font-size: 12px;
                line-height: 17px;
              }

              ul:nth-of-type(1) {
                position: absolute;
                top: 40px;
                left: 32px;
                width: 16px;
                height: 16px;
                background: url(../../assets/images/visualizations/energyIcon@2x.png) no-repeat center/100% 16px;
              }

              p:nth-of-type(1) {
                position: absolute;
                top: 40px;
                left: 32px;
                width: 16px;
                height: 16px;
                background: url(../../assets/images/visualizations/disChargeIcon@2x.png) no-repeat center/100% 16px;
              }

              div:nth-of-type(3) {
                @include common-biaotihei-style;
                top: 33px;
                left: 52px;
                width: 41px;
                height: 29px;
                font-size: 22px;
                color: #45ffe0;
                line-height: 29px;
              }

              div:nth-of-type(4) {
                @include common-pingfang-style;
                top: 12px;
                left: 160px;
                width: 48px;
                height: 17px;
                font-size: 12px;
                line-height: 17px;
              }

              div:nth-of-type(5) {
                @include common-biaotihei-style;
                top: 33px;
                right: 64px;
                height: 29px;
                font-size: 22px;
                color: #16dd8e;
                line-height: 29px;
              }

              div:nth-of-type(6) {
                @include common-pingfang-style;
                top: 39px;
                left: 212px;
                width: 12px;
                height: 17px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.65);
                line-height: 17px;
              }
            }

            .content_left_energy_daily {
              position: absolute;
              top: 190px;
              left: 16px;
              width: 176px;
              height: 164px;
              background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
              border-radius: 2px;
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;

              div:nth-of-type(1) {
                @include common-pingfang-style;
                height: 20px;
                top: 16px;
                left: 16px;
                color: #ffffff;
                line-height: 20px;
              }
              span:nth-of-type(1) {
                @include common-biaotihei-style;
                top: 40px;
                left: 16px;
                height: 29px;
                font-size: 22px;
                color: #459cff;
                line-height: 29px;
              }
              div:nth-of-type(2) {
                @include common-biaotihei-style;
                top: 40px;
                left: 16px;
                height: 29px;
                font-size: 22px;
                color: #459cff;
                line-height: 29px;
              }

              div:nth-of-type(3) {
                @include common-pingfang-style;
                top: 94px;
                left: 16px;
                height: 20px;
                line-height: 20px;
              }
              span:nth-of-type(2) {
                @include common-biaotihei-style;
                top: 119px;
                left: 16px;
                height: 29px;
                font-size: 22px;
                color: #ffbb45;
                line-height: 29px;
              }
              div:nth-of-type(4) {
                @include common-biaotihei-style;
                top: 119px;
                left: 16px;
                height: 29px;
                font-size: 22px;
                color: #ffbb45;
                line-height: 29px;
              }
            }

            .content_left_energy_monthly {
              position: absolute;
              top: 190px;
              left: 208px;
              width: 176px;
              height: 164px;
              background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
              border-radius: 2px;
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;

              div:nth-of-type(1) {
                @include common-pingfang-style;
                top: 16px;
                left: 10px;
                height: 20px;
                line-height: 20px;
              }

              div:nth-of-type(2) {
                @include common-biaotihei-style;
                top: 40px;
                left: 10px;
                height: 29px;
                color: #459cff;
                line-height: 29px;
              }

              div:nth-of-type(3) {
                @include common-pingfang-style;
                top: 94px;
                left: 10px;
                height: 20px;
              }

              div:nth-of-type(4) {
                @include common-biaotihei-style;
                top: 119px;
                left: 10px;
                height: 29px;
                color: #ffbb45;
                line-height: 29px;
              }
            }
          }
        }
      }

      .content_center {
        flex: 1;

        .chargeLocation {
          transition: all 0.5s linear;
          div:nth-of-type(1) {
            position: absolute;
            top: 267px;
            left: 694px;
            width: 70px;
            height: 170px;
            background: url(../../assets/images/visualizations/PVlocation@2x.png) no-repeat center/100% 170px;
          }
          div:hover {
            transform: scale(1.05);
          }
          div:nth-of-type(2) {
            position: absolute;
            top: 219px;
            left: 682px;
            width: 92px;
            height: 40px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            line-height: 40px;
            background: url(../../assets/images/visualizations/locationNamebg@2x.png) no-repeat center/100% 40px;
          }
        }

        .chargeStation {
          transition: all 0.5s linear;
          div:nth-of-type(1) {
            position: absolute;
            top: 712px;
            left: 1046px;
            width: 70px;
            height: 170px;
            background: url(../../assets/images/visualizations/chargeStationLocation@2x.png) no-repeat center/100% 170px;
          }
          div:hover {
            transform: scale(1.05);
          }

          div:nth-of-type(2) {
            position: absolute;
            top: 664px;
            left: 1035px;
            width: 92px;
            height: 40px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            line-height: 40px;
            background: url(../../assets/images/visualizations/locationNamebg@2x.png) no-repeat center/100% 40px;
          }
        }

        .photovoltaicstation {
          transition: all 0.5s linear;
          div:hover {
            transform: scale(1.05);
          }
          div:nth-of-type(1) {
            position: absolute;
            top: 538px;
            left: 1283px;
            width: 70px;
            height: 170px;
            background: url(../../assets/images/visualizations/energyStorageStationLocation@2x.png) no-repeat
              center/100% 170px;
          }

          div:nth-of-type(2) {
            position: absolute;
            top: 490px;
            left: 1272px;
            width: 92px;
            height: 40px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #fff;
            line-height: 40px;
            background: url(../../assets/images/visualizations/locationNamebg@2x.png) no-repeat center/100% 40px;
          }
        }
      }

      .content_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 400px;
        height: 100%;

        .content_right_charge {
          @include common-content-style;
          position: relative;
          width: 100%;
          height: 300px;

          .content_right_charge_middleLine {
            @include content-common-bottom;
            top: 209px;
          }

          .content_right_charge_bottomLine {
            @include content-common-bottom;
          }

          .content_right_charge_content {
            width: 1000%;
            height: calc(100% - 40px);

            .content_right_charge_content_pic {
              position: absolute;
              top: 56px;
              left: 16px;
              width: 160px;
              height: 140px;
              background: url(../../assets/images/visualizations/chargePic.png) no-repeat center/100% 140px;
            }

            .content_right_charge_content_operation {
              position: absolute;
              top: 56px;
              left: 192px;
              width: 192px;
              height: 40px;
              background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
              border-radius: 2px;
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;

              div:nth-of-type(1) {
                position: absolute;
                top: 12px;
                left: 8px;
                width: 16px;
                height: 16px;
                background: url(../../assets/images/visualizations/icon-security@2x.png) no-repeat center/100% 16px;
              }

              div:nth-of-type(2) {
                @include common-pingfang-style;
                left: 28px;
                top: 12px;
                width: 48px;
                height: 17px;
                font-size: 12px;
                line-height: 17px;
              }

              div:nth-of-type(3) {
                @include common-biaotihei-style;
                top: 6px;
                right: 22px;
                height: 29px;
                font-size: 22px;
                color: #16dd8e;
                line-height: 29px;
              }

              div:nth-of-type(4) {
                @include common-pingfang-style;
                top: 12px;
                left: 172px;
                height: 17px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.65);
                line-height: 17px;
              }
            }

            .content_right_charge_content_pile {
              position: absolute;
              top: 104px;
              left: 192px;
              width: 192px;
              height: 40px;
              background: linear-gradient(180deg, rgba(49, 138, 240, 0.37) 0%, rgba(8, 35, 66, 0.37) 100%);
              border-radius: 2px;
              border: 1px solid;
              border-image: linear-gradient(180deg, rgba(22, 115, 221, 1), rgba(255, 255, 255, 0.2)) 1 1;

              div:nth-of-type(1) {
                position: absolute;
                top: 11px;
                left: 8px;
                width: 16px;
                height: 16px;
                background: url(../../assets//images/visualizations/pieIcon@2x.png) no-repeat center/100% 16px;
              }

              div:nth-of-type(2) {
                @include common-pingfang-style;
                top: 10px;
                left: 28px;
                height: 17px;
                font-size: 12px;
                line-height: 17px;
              }

              div:nth-of-type(3) {
                @include common-biaotihei-style;
                top: 4px;
                right: 24px;
                height: 29px;
                font-size: 22px;
                color: #52e7ff;
                line-height: 29px;
              }

              div:nth-of-type(4) {
                @include common-pingfang-style;
                top: 10px;
                left: 172px;
                height: 17px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.65);
                line-height: 17px;
              }
            }

            // 实时功率
            div:nth-of-type(4) {
              @include common-pingfang-style;
              top: 152px;
              left: 192px;
              height: 17px;
              font-size: 12px;
              color: #ffffff;
              line-height: 17px;
            }
            span:nth-of-type(1) {
              @include common-biaotihei-style;
              top: 173px;
              left: 192px;
              height: 29px;
              font-size: 22px;
              color: #ffbb45;
              line-height: 29px;
            }
            // 实时功率值
            div:nth-of-type(5) {
              @include common-biaotihei-style;
              top: 173px;
              left: 192px;
              height: 29px;
              font-size: 22px;
              color: #ffbb45;
              line-height: 29px;
            }

            // 今日充电量
            div:nth-of-type(6) {
              @include common-pingfang-style;
              top: 226px;
              left: 24px;
              height: 20px;
              line-height: 20px;
            }

            // 今日充电量值
            div:nth-of-type(7) {
              @include common-biaotihei-style;
              top: 250px;
              left: 24px;
              height: 29px;
              font-size: 22px;
              color: #45ffe0;
              line-height: 29px;
            }

            // 本月充电量 (kWh)
            div:nth-of-type(8) {
              @include common-pingfang-style;
              top: 226px;
              left: 220px;
              height: 20px;
              line-height: 20px;
            }

            // 本月充电量值
            div:nth-of-type(9) {
              @include common-biaotihei-style;
              top: 250px;
              left: 222px;
              height: 29px;
              font-size: 22px;
              color: #45ffe0;
              line-height: 29px;
            }
          }
        }

        .content_right_greenenergy {
          @include common-content-style;
          position: relative;
          width: 100%;
          height: 280px;

          .content_right_greenenergy_bottomLine {
            @include content-common-bottom;
          }

          .content_right_greenenergy_content {
            width: 100%;
            height: calc(100% - 40px);

            // CO2减排量
            div:nth-of-type(1) {
              @include common-biaotihei-style;
              top: 64px;
              left: 144px;
              width: 101px;
              height: 26px;
              font-size: 20px;
              color: #ffffff;
              line-height: 26px;
            }

            .content_right_greenenergy_content_leftBubble {
              position: absolute;
              top: 88px;
              left: 16px;
              width: 124px;
              height: 124px;
              background: url(../../assets/images/visualizations/dailyGreenEnergy@2x.png) no-repeat center/100% 124px;

              // 今日碳排
              div:nth-of-type(1) {
                @include common-biaotihei-style;
                top: 36px;
                left: 0;
                right: 0;
                margin: auto;
                height: 29px;
                font-size: 22px;
                color: #16dd8e;
                line-height: 29px;
              }

              // 今日 (kg)
              div:nth-of-type(2) {
                @include common-pingfang-style;
                z-index: 1;
                top: 69px;
                left: 29px;
                width: 67px;
                height: 22px;
                font-size: 16px;
                line-height: 22px;
              }
            }

            .content_right_greenenergy_content_centerBubble {
              position: absolute;
              z-index: 2;
              top: 108px;
              left: 127px;
              width: 144px;
              height: 144px;
              background: url(../../assets/images/visualizations/monthlyGreenEnergy@2x.png) no-repeat center/100% 144px;

              // 本月碳排
              div:nth-of-type(1) {
                @include common-biaotihei-style;
                top: 48px;
                left: 0;
                right: 0;
                margin: auto;
                width: auto;
                height: 29px;
                font-size: 22px;
                color: #16dd8e;
                line-height: 29px;
              }

              // 本月 (t)
              div:nth-of-type(2) {
                @include common-pingfang-style;
                top: 81px;
                left: 0;
                right: 0;
                margin: auto;
                width: auto;
                height: 22px;
                font-size: 16px;
                line-height: 22px;
              }
            }

            .content_right_greenenergy_content_rightBubble {
              position: absolute;
              z-index: 1;
              top: 88px;
              left: 260px;
              width: 124px;
              height: 124px;
              background: url(../../assets/images/visualizations/lastMonthGreenEnergy@2x.png) no-repeat center/100%
                124px;

              // 上月碳排
              div:nth-of-type(1) {
                @include common-biaotihei-style;
                top: 36px;
                left: 0px;
                right: 0;
                margin: auto;
                width: auto;
                height: 29px;
                font-size: 22px;
                color: #16dd8e;
                line-height: 29px;
              }

              // 上月 (t)
              div:nth-of-type(2) {
                @include common-pingfang-style;
                top: 69px;
                left: 35px;
                width: 54px;
                height: 22px;
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }

        .content_right_electric {
          @include common-content-style;
          position: relative;
          width: 100%;
          height: 342px;

          .content_right_electric_content {
            position: absolute;
            bottom: 0;
            height: calc(100% - 40px);
            width: 100%;
          }

          .content_right_electric_bottomLine {
            @include content-common-bottom;
          }
        }
      }
    }
  }
}

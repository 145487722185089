@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url('../../../../assets/fonts/YouSheBiaoTiHei-2.ttf') format('truetype');
}

.header {
  position: absolute;
  width: 100%;
  height: 40px;
  z-index: 2;
  background: url(../../../../assets/images/visualizations/bg-tittle@2x.png) no-repeat center/100% 40px;

  p {
    position: absolute;
    top: 8px;
    left: 24px;
    height: 23px;
    font-size: 18px;
    font-family: 'YouSheBiaoTiHei';
    color: #ffffff;
    line-height: 23px;
  }

  div {
    position: absolute;
    top: 10px;
    right: 16px;
    width: 70px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #00d2ff;
    line-height: 20px;
    cursor: pointer;
  }
}
